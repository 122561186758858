<template>
  <div class="">
    <div
      class="spinner-wrapper d-flex justify-content-center align-items-center"
      v-if="isLoading"
    >
      <div class="loader"></div>
    </div>
    <Layout
      class="webinar-layout-wrapper"
      :class="{ 'is-live': event.status == 'live' }"
      v-if="!isLoading"
    >
      <Navbar
        :windowSizes="windowSizes"
        :isSiderControl="true"
        @siderHandler="collapsedSider"
      />
      <StatusUpcoming v-if="event.status === 'upcoming'" />
      <StatusEnded
        v-else-if="
          event.status === 'completed' &&
          !event.enableVOD &&
          !event.enableVideoVOD
        "
      />
      <template v-else>
        <Layout
          class="main-layout"
          :class="{ 'is-mobile-sider': windowSizes.width < 768 }"
        >
          <WebinarMediaContent />
          <Sider
            class="live-sider"
            v-if="hasWidgets && windowSizes.width > 767"
            :class="{ hasStreams: hasStreams }"
            ref="sider"
            width="360"
            collapsed-width="0"
            hide-trigger
            collapsible
            breakpoint="md"
            v-model="isCollapsed"
          >
            <Layout>
              <WidgetContainer :isCollapsed="isCollapsed"></WidgetContainer>
            </Layout>
          </Sider>
          <div class="mobile-sider" v-else>
            <WidgetContainer :isCollapsed="isCollapsed"></WidgetContainer>
          </div>
        </Layout>
      </template>
    </Layout>
  </div>
</template>

<script>
/* eslint-disable no-underscore-dangle */
import { auth, db } from '@/firebase';
import axios from 'axios';
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import Navbar from '@/components/Navbar.vue';
import StatusUpcoming from '@/components/StatusUpcoming.vue';
import StatusEnded from '@/components/StatusEnded.vue';
import WebinarMediaContent from '@/components/WebinarMediaContent.vue';
import WidgetContainer from '@/components/widget/NewWidgetContainer.vue';

export default {
  components: {
    Navbar,
    StatusUpcoming,
    StatusEnded,
    WebinarMediaContent,
    WidgetContainer,
  },
  data() {
    return {
      modalText: {},
      isLoading: true,
      isCollapsed: false,
      bgImgStyle: {},
      selectedLang: 'en',
      windowSizes: {
        width: 1280,
        height: 720,
      },
    };
  },
  computed: {
    ...mapGetters({
      streamList: 'stream/list',
      widgetList: 'widget/list',
      user: 'user/current',
      event: 'event/current',
      stream: 'stream/current',
      appSettings: 'appsettings/current',
    }),
    hasStreams() {
      if (this.event.status != 'live') return false;
      if (this.streamList.length > 1) return true;
      return false;
    },
    hasWidgets() {
      return this.widgetList.length > 1;
    },
    siderIcon() {
      if (this.isCollapsed) {
        return 'ios-arrow-back';
      }
      return 'ios-arrow-forward';
    },
  },
  methods: {
    ...mapActions({
      setCurrentEvent: 'event/setCurrent',
      setCurrentUser: 'user/setCurrent',
      setStreamList: 'stream/setList',
      setCurrentStream: 'stream/setCurrent',
      getAppSettings: 'appsettings/get',
      setWidgetList: 'widget/setList',
    }),
    async signInWithMagnetToken() {
      const data = await axios({
        method: 'GET',
        url: `${process.env.VUE_APP_BASE_URL}/registration/me`,
        headers: { Authorization: `Bearer ${this.$route.query.t}` },
      });
      return data;
    },
    async signInFromLocalStorage() {
      const token = localStorage.getItem(this.$route.params.slug);
      const data = await axios({
        method: 'GET',
        url: `${process.env.VUE_APP_BASE_URL}/registration/me`,
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    },
    openWidget(index) {
      const widget = this.widgets[index];

      if (_.isEqual(this.currentWidget, widget) || this.isCollapsed) {
        this.collapsedSider();
      }
      this.currentWidget = widget;
      // console.log(this.currentWidget);
    },
    closeWidget() {
      this.collapsedSider();
      // this.currentWidget = undefined
    },
    collapsedSider() {
      this.$refs.sider.toggleCollapse();
    },
    handleResize() {
      this.windowSizes = {
        width: window.innerWidth,
        height: window.innerHeight,
      };
    },
  },
  async mounted() {
    let data = null;

    // get app Settings
    await this.getAppSettings();

    // Fetch event and registration data
    try {
      if (this.$route.query.t) {
        data = await this.signInWithMagnetToken();
      } else {
        data = await this.signInFromLocalStorage();
      }

      this.setCurrentUser(data.data.registration);
      this.setCurrentEvent(data.data.event);
      this.setStreamList(data.data.streams);
      this.setCurrentStream(data.data.streams[0]);
    } catch (error) {
      this.$Modal.error({
        title: 'Error',
        content: 'Registration required for this event',
        okText: 'Okey',
      });
      if (_.has(this.$route, 'params.slug')) {
        window.location = `${this.appSettings.general.landingUrl}/${this.$route.params.slug}`;
      }

      return false;
    }

    // signin via firebase
    try {
      await auth().signInWithCustomToken(this.user.fbToken);
    } catch (err) {
      this.$Modal.error({
        title: 'Error',
        content: 'Invalid access',
        okText: 'Okey',
      });
      this.$bvModal.show('dialogModal');
      return false;
    }

    // Put token to the localStorage for further page refresh
    localStorage.setItem(this.event._id, this.user.token);

    // if signin completed, remove the token from address bar
    this.$router.push(`/${this.event._id}`).catch(() => {});

    // assign background image
    if (_.has(this.$root, 'event.pageSettings.bgImgUrl')) {
      this.bgImgStyle = {
        backgroundImage: `url(${this.event.pageSettings.bgImgUrl})`,
        backgroundPositionX: this.event.pageSettings.bgImgPosition,
      };
    }

    // listen auth changes and refresh the token if necessary
    auth().onAuthStateChanged(async (user) => {
      if (!user) {
        const {
          data: { fbToken },
        } = await axios({
          method: 'GET',
          url: `${process.env.VUE_APP_BASE_URL}/refresh`,
          headers: { Authorization: `Bearer ${this.user.token}` },
        });

        await auth().signInWithCustomToken(fbToken);
      }
    });

    // listen event status updates
    db.collection('event')
      .doc(this.event._id)
      .onSnapshot({}, (doc) => {
        this.setCurrentEvent(Object.assign(this.event, doc.data()));
      });

    // get Widgets
    axios({
      method: 'GET',
      url: 'https://api-lb.livecasthq.com/widgets/',
      params: {
        webcastId: this.event.webcastId,
        '$sort[createdAt]': -1,
      },
    })
      .then((res) => {
        this.setWidgetList(res.data.data);
      })
      .finally(() => {
        this.isLoading = false;
      });

    return true;
  },
};
</script>
<style lang="less">
.webinar-status {
  height: 100vh;
  padding: 64px 16px 16px 16px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;
  color: #272727;
  line-height: 1.2;
  .status-box {
    width: 100%;
    max-width: 720px;
    padding: 48px 16px;
    background: #fff;
    text-align: center;
    .timer {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 48px;
      &--item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 12px;
        &__desc {
          font-weight: 500;
          font-size: 14px;
          color: #999999;
        }
        &__time {
          font-weight: 500;
          font-size: 56px;
          color: #000000;
          line-height: 1;
          @media (max-width: 575px) {
            font-size: 32px;
          }
        }
      }
    }
    h2 {
      font-weight: 600;
      font-size: 32px;
      margin: 0;
      @media (max-width: 575px) {
        font-size: 24px;
      }
    }
    h3 {
      font-weight: 500;
      font-size: 18px;
      margin: 8px 0 0 0;
      @media (max-width: 575px) {
        font-size: 16px;
      }
    }
    p {
      font-weight: 500;
      font-size: 12px;
      color: #999999;
      margin: 8px 0 0 0;
    }
  }
  &.is-ended {
    .status-box {
      padding: 96px 16px;
    }
  }
}
</style>

<style lang="less" scopped>
.collapsed-menu i {
  transform: translateX(5px);
  transition: font-size 0.2s ease 0.2s, transform 0.2s ease 0.2s;
  vertical-align: middle;
  font-size: 22px;
}

.sider-toggle-btn {
  position: absolute;
  right: 485px;
  width: 36px !important;
  height: 36px !important;
  top: 70px;
  z-index: 99999;
  &.collapsed {
    right: 10px;
  }
}

.main-layout {
  &.is-mobile-sider {
    @media (max-width: 575px) {
      flex-direction: column;
      padding-top: 64px;
      height: 100vh;
      .webinar-media-content-wrapper,
      .mobile-sider {
        width: 100%;
      }
      .mobile-sider {
        flex-grow: 1;
        overflow: auto;
      }
    }
  }
}

.live-page {
  height: 100vh;
}

.live-sider {
  border-left: 1px solid #e9eaec;
  padding-top: 64px;
  @media (min-width: 768px) {
    max-height: 100vh;
    overflow: auto;
  }
}

@media screen and (max-width: 992px) {
  .live-page {
    .live-content {
      width: 100%;
      z-index: 1000;
      @media screen and (min-width: 992px) {
        transform: translateY(-50%);
        top: 50%;
        position: relative;
        width: 80%;
        margin: auto;
      }
    }
    .live-sider {
      border-left: none;
    }

    .main-layout {
      //flex-wrap: wrap;
    }

    .ivu-layout-sider {
      width: 100% !important;
      max-width: inherit !important;
      flex-basis: 100% !important;
      flex: inherit !important;
      padding-top: 56.25%;
      position: fixed;
      height: 100vh;
    }

    .ivu-layout-content {
      //height: 45vh;

      overflow: visible;
      overflow-x: hidden;
    }

    .ivu-layout-sider {
      //height: ~"calc(100vh - (45vh + 30px))";
      min-width: inherit !important;
    }

    .ivu-layout {
      padding-bottom: 0 !important;
      height: 100%;
    }
  }
  .hasStreams .ivu-layout-sider {
    padding-top: calc(56.25% + 48px);
  }

  .hasCTA .ivu-layout-sider {
    padding-top: calc(56.25% + 48px);
  }
  .poweredby_logo {
    background-image: url(/img/powered_by_logo.png);
    background-color: transparent;
    width: 96px;
    height: 66px;
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0.2;
    left: 32px;
    position: absolute;
    bottom: 0px;
  }
}

// Spinner
.spinner-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader,
.loader:after {
  border-radius: 100%;
  width: 64px;
  height: 64px;
}
.loader {
  position: relative;
  border-top: 4px solid rgba(0, 0, 0, 0.1);
  border-right: 4px solid rgba(0, 0, 0, 0.1);
  border-bottom: 4px solid #007bff;
  border-left: 4px solid #007bff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 0.6s infinite linear;
  animation: load8 0.6s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
