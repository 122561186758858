<template>
  <div class="qna-form">
    <div class="new-question">
      <div class="new-question-line">
        <Input
          v-model="newQuestion"
          type="textarea"
          :rows="newQuestionRows"
          placeholder="Please type your question"
          class="new-question-field is-qna-input"
          :disabled="isLoading"
        />
      </div>
      <div class="new-question-line">
        <div class="new-question-message">
          <transition name="slide-fade">
            <div :class="formMassage.type" v-if="formMassage.isActive">
              {{ formMassage.massage }}
            </div>
          </transition>
        </div>
        <Button
          type="primary"
          @click="createNewQuestion"
          :disabled="isLoading || !(newQuestion.length >= 1)"
          :loading="isLoading"
          >{{ !isLoading ? "Send" : null }}</Button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import axios from 'axios';

export default {
  data() {
    return {
      newQuestion: '',
      newQuestionRows: 3,
      formMassage: { type: '', massage: '', isActive: false },
      timer: null,
      isLoading: false,
    };
  },
  props: ['senderInfo'],
  computed: {
    ...mapGetters({
      streamList: 'stream/list',
      widgetList: 'widget/list',
      user: 'user/current',
      event: 'event/current',
      stream: 'stream/current',
      appSettings: 'appsettings/current',
    }),
  },
  methods: {
    /**
     * this Function for clear the Textarea Quesiotn.
     * @returns void
     */
    clearQuestionInput() {
      this.newQuestion = '';
    },
    /**
     * Creat new question object and send request to backend
     * @returns void.
     */
    createNewQuestion() {
      clearTimeout(this.timer);
      this.clearFormMassage();
      this.timer = null;
      this.isLoading = true;
      const data = {
        webcastId: this.event.webcastId,
        name: this.senderInfo.name,
        location: this.senderInfo.location,
        text: this.newQuestion,
        bucket: 'inbox',
        isAnswered: false,
        isApproved: false,
      };

      if (data.text) {
        axios({
          method: 'POST',
          url: 'https://api-lb.livecasthq.com/qna/',
          data,
        })
          .then((res) => {
            this.formMassage = {
              ...this.formMassage,
              isActive: true,
              massage: 'Your question has been sent',
              type: 'success',
            };
            this.clearQuestionInput();
            this.showFormMessage();
          })
          .catch(() => {
            this.formMassage = {
              ...this.formMassage,
              isActive: true,
              massage: 'Error! Please try again.',
              type: 'error',
            };
            this.showFormMessage();
            this.isLoading = false;
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    /**
     * Clear Form result Message.
     * @returns void
     */
    clearFormMassage() {
      this.formMassage = {
        ...this.formMassage,
        isActive: false,
        massage: '',
        type: '',
      };
    },
    /**
     * show From result message for 5000ms .
     * @returns void
     */
    showFormMessage() {
      this.timer = setTimeout(() => {
        this.clearFormMassage();
      }, 3000);
    },
  },
};
</script>

<style lang="less" scoped>
.new-question {
  width: 100%;

  &-line {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  &-message {
    display: flex;
    align-items: center;
    font-size: 12px;
    .success {
      color: #19be6b;
    }
    .error {
      color: #ed4014;
    }
  }

  &-field {
    margin-top: 0 !important;
  }

  &--submit {
    margin-top: 16px;
  }
}

.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}
</style>
