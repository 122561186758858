<template>
  <div class="webinar-status is-upcoming">
    <div class="status-box">
      <h2>Please Wait</h2>
      <h3>The webcast has not started yet</h3>
      <p style="font-size: 16px">
        {{ this.event.startAt.toDate() | moment("LL") }}
        <br />
        {{ this.event.startAt.toDate() | moment("h:mm") }}&nbsp;-&nbsp;{{
          this.event.endAt.toDate() | moment("h:mm")
        }}
      </p>
      <!--
      <div class="timer">
        <div class="timer--item">
          <span class="timer--item__time">{{ this.diff.days }}</span>
          <div class="timer--item__desc">Days</div>
        </div>
        <div class="timer--item">
          <span class="timer--item__time">{{ this.diff.hours }}</span>
          <div class="timer--item__desc">Hours</div>
        </div>
        <div class="timer--item">
          <span class="timer--item__time">{{ this.diff.minutes }}</span>
          <div class="timer--item__desc">Minutes</div>
        </div>
        <div class="timer--item">
          <span class="timer--item__time">{{ this.diff.seconds }}</span>
          <div class="timer--item__desc">Seconds</div>
        </div>
      </div>
    --></div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      diff: {
        interval: null,
        days: null,
        hours: null,
        minutes: null,
        seconds: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      streamList: 'stream/list',
      user: 'user/current',
      event: 'event/current',
      stream: 'stream/current',
      appSettings: 'appsettings/current',
    }),
  },
  methods: {
    diffHandler() {
      if (this.webcast) {
        const now = moment(Date.now());
        const startAt = moment(this.webcast.startAt);
        this.diff.days = startAt.diff(now, 'days');
        this.diff.hours = startAt
          .diff(now, 'hours')
          .toString()
          .slice(-2);
        this.diff.minutes = startAt
          .diff(now, 'minutes')
          .toString()
          .slice(-2);
        this.diff.seconds = startAt
          .diff(now, 'seconds')
          .toString()
          .slice(-2);
      }
    },
  },
  mounted() {
    this.diff.interval = setInterval(() => {
      this.diffHandler();
    }, 1000);
  },
  destroyed() {
    clearInterval(this.diff.interval);
    this.diff.interval = null;
  },
};
</script>
