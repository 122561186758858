<template>
  <div class="widget">
    <!-- header -->

    <Panel :name="widgetData.title">
      {{ widgetData.title }}
      <slot name="content" />
    </Panel>
  </div>
</template>

<script>
export default {
  props: ['widgetData'],

  methods: {
    closeWidget() {
      // this.$emit('close')
    },
  },
};
</script>

<style lang="less">
.header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid #e9eaec;
  padding: 0px 20px;
}
.title {
  font-weight: normal;
}

.ivu-btn {
  &:focus {
    box-shadow: none;
  }
}

.widget {
  &-content {
    height: 100%;
    @media screen and (min-width: 992px) {
      height: calc(100% - 56px);
    }

    &--html {
      overflow: auto;
      padding-left: 30px;
      padding-right: 30px;
      ul {
        padding-left: 45px;
      }
    }
    &--qna {
      .qna-actions {
        position: relative;
      }
      .new-question {
        display: inline-block;
      }
    }
  }
}
</style>
