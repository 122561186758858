<template>
  <div class="webinar-media-content-wrapper">
    <AudioPlayerVOD v-if="event.enableVOD" />
    <VideoPlayerVOD v-if="event.enableVideoVOD" />
    <AudioPlayer2 v-else />

    <!--
    <LiveSlides :currentSlide="currentSlide"></LiveSlides>
    <AudioPlayer @updateCurrentSlide="updateSlide"></AudioPlayer>
    -->
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
// import LiveSlides from '@/components/webinar/LiveSlides.vue'
import AudioPlayer2 from '@/components/AudioPlayer2.vue';
import AudioPlayerVOD from '@/components/AudioPlayerVOD.vue';
import VideoPlayerVOD from '@/components/VideoPlayerVOD.vue';

export default {
  name: 'webinar-media-content',
  components: {
    // LiveSlides,
    AudioPlayer2,
    AudioPlayerVOD,
    VideoPlayerVOD,
  },
  data() {
    return {
      currentSlide: 0,
    };
  },
  methods: {
    updateSlide(data) {
      console.log(data);
      this.currentSlide = data;
    },
  },
  computed: {
    ...mapGetters({
      streamList: 'stream/list',
      user: 'user/current',
      event: 'event/current',
      stream: 'stream/current',
      appSettings: 'appsettings/current',
    }),
  },
  created() {
    console.log(this.event);
  },
};
</script>
<style lang="less" scoped>
.webinar-media-content-wrapper {
  padding: 16px;
  background-color: #f5f5f5;
  width: 100%;
  z-index: 1;
  top: 64px;
  height: 100vh;
  position: relative;
  max-height: calc(100vh - 64px);
  @media (max-width: 575px) {
    padding: 2px;
  }
}
</style>
