<template>
  <Header class="lc-header">
    <div class="lc-header-container">
      <div class="lc-header-cols">
        <div class="lc-header-cols-item is-left">
          <h1>{{ event.title }}</h1>
        </div>
        <div class="lc-header-cols-item is-right">
          <!--
          <Button class="lc-header-logout" @click="logoutClick()" v-if="hasReg"
            >Logout</Button
          >
          -->
          <Button
            class="lc-btn is-collapse-sider"
            size="large"
            icon="ios-menu"
            v-if="isSiderControl && windowSizes.width > 575"
            @click="handleCollapseSider()"
          />
        </div>
      </div>
    </div>
  </Header>
</template>

<script>
/* eslint-disable no-underscore-dangle */
import { mapGetters, mapActions } from 'vuex';

export default {
  props: {
    isSiderControl: {
      type: Boolean,
      required: false,
      default: false,
    },
    windowSizes: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },

  computed: {
    ...mapGetters({
      streamList: 'stream/list',
      user: 'user/current',
      event: 'event/current',
      stream: 'stream/current',
      appSettings: 'appsettings/current',
    }),
  },
  methods: {
    ...mapActions('registrations', {
      logout: 'logout',
    }),
    handleCollapseSider() {
      this.$emit('siderHandler');
    },
  },
};
</script>

<style lang="less">
.lc-header {
  position: fixed;
  width: 100%;
  @media (max-width: 575px) {
    padding-left: 0;
    padding: 0;
  }
  @media (min-width: 768px) {
    padding-left: 16px;
    padding-right: 16px;
  }
  .lc-header-container {
    margin-top: 0;
    margin-bottom: 0;
    height: 100%;
    @media (max-width: 575px) {
      padding-left: 8px;
      padding: 8px;
    }
  }
  .lc-header-cols {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    height: 100%;
    &-item {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 33%;
      height: 100%;
      &.is-left {
        justify-content: flex-start;
      }
      &.is-center {
        justify-content: center;
      }
      &.is-right {
        justify-content: flex-end;
        display: flex;
        align-items: center;
      }
      h1 {
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        color: #000;
      }
      .lc-header-logo {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        max-width: 80%;
        max-height: 100%;
      }
      .lc-header-logout {
        @media (max-width: 540px) {
          padding: 6px 12px;
          font-size: 12px;
        }
      }
    }
  }
  .lc-btn {
    &.is-collapse-sider {
      display: block;
      margin-left: 8px;
      font-size: 24px;
      line-height: 1 !important;
      border: solid 1px transparent;
      color: #000;
      .ivu-icon {
        display: block;
        line-height: 1 !important;
      }
    }
  }
}
</style>
