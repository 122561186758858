<template>
  <div class="widgets-wrapper new-widget-container">
    <!--<widget-menu  @switchToWidgetEvent="selectWidget"></widget-menu>-->

    <Collapse style="width: 100%" v-model="openNames">
      <Panel
        v-for="(widget, index) in widgets"
        :key="index"
        :name="widget.title"
      >
        {{ widget.title }}
        <div
          v-if="widget.html && widget.type === 'HTML'"
          slot="content"
          v-html="widget.html"
          class="widget-content widget-content--html"
        ></div>

        <div
          v-if="widget.iframeUrl && widget.type === 'Iframe'"
          slot="content"
          class="widget-content widget-content--iframe"
        >
          <iframe :src="widget.iframeUrl" frameborder="0" width="100%"></iframe>
        </div>

        <div
          v-if="widget.type === 'Q&A'"
          slot="content"
          class="widget-content widget-content--qna"
        >
          <qna-widget :widgetData="widget" />
        </div>

        <div
          v-if="widget.type === 'Speakers'"
          slot="content"
          class="widget-content widget-content--speakers"
        >
          <SpeakerList :widgetData="widget" />
        </div>
        <div
          v-if="widget.type === 'Downloads'"
          slot="content"
          class="widget-content widget-content--downloads"
        >
          <DownloadList :widgetData="widget" />
        </div>
      </Panel>
    </Collapse>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import Widget2 from '@/components/widget/Widget2.vue';
import WidgetMenu2 from '@/components/widget/WidgetMenu2.vue';
import QnaWidget from '@/components/widget/QnaActions.vue';
import DownloadList from '@/components/widget/DownloadList2.vue';
import SpeakerList from '@/components/widget/SpeakerList2.vue';

export default {
  data() {
    return {
      currentWidget: null,
      isReady: false,
      openNames: ['Speakers', 'Downloads', 'Q&A'],
    };
  },
  components: {
    Widget2,
    QnaWidget,
    WidgetMenu2,
    SpeakerList,
    DownloadList,
  },
  computed: {
    ...mapGetters({
      widgetList: 'widget/list',
      webcast: 'event/current',
    }),
    widgets() {
      return _.sortBy(this.widgetList, ['createdAt']);
    },
  },
  methods: {},
  created() {
    // set the first widgetType by default
  },
};
</script>

<style lang="less">
.new-widget-container {
  @media (min-width: 576px) {
    height: 100%;
  }
  @media (max-width: 575px) {
    overflow: auto;
  }
  .ivu-collapse {
    background: #fff;
    border: none;
    border-radius: 0;
    &-header {
      border: 0;
      border: none !important;
      border-top: solid 1px #f5f5f5 !important;
      padding-right: 32px;
      font-size: 12px;
      font-weight: 700;
      color: #999 !important;
      text-transform: uppercase;
      .ivu-icon {
        position: absolute;
        right: 16px;
        margin: -8px 0 0 0 !important;
        top: 50%;
        font-size: 16px;
        color: #272727;
      }
    }
    &-item {
      border: 0;
    }
    &-item-active {
      .ivu-collapse-header {
        position: relative;
      }
    }
  }
}
.widgets {
  &-wrapper {
    height: 100%;
  }
  &-area {
    height: 100%;
    .widget {
      height: 100%;
      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }

  &-list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-bottom: 0;
    margin-right: 10px;
    font-weight: bold;
    cursor: pointer;
    > li {
      &:not(:last-of-type) {
        margin-right: 20px;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .widgets-wrapper {
    height: 100%;
    /*padding-top: 48px;*/
  }

  .widget-content {
    height: 100%;
    padding-bottom: 56px;
    //height: ~"calc(100vh - (45vh + 30px + 46px))";
    &:not(.widget-content--iframe) {
      //padding: 0;
    }
  }
}
</style>
