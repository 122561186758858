import Vue from 'vue';
import '@firebase/polyfill';
import './registerServiceWorker';
import VueMoment from 'vue-moment';
import moment from 'moment-timezone';
import iView from 'iview';
import router from './router';
import store from './store';

import App from './App.vue';

import 'iview/dist/styles/iview.css';
import './less/lc-iview.less';

Vue.config.productionTip = false;

Vue.use(iView);
Vue.use(VueMoment, {
  moment,
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
