<template>
  <div class="speaker-list" v-if="!isLoading">
    <div class="speaker-list-item" v-for="s in speakers" :key="s._id">
      <div class="speaker-img">
        <img :src="s.imgPath" />
      </div>
      <div class="speaker-info-wrapper">
        <div class="speaker-name">{{ s.name }}</div>
        <div class="speaker-title">{{ s.title }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';

export default {
  name: 'Speakers',
  data() {
    return {
      speakerImageUrl: null,
      speakerList: {},
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters({
      streamList: 'stream/list',
      widgetList: 'widget/list',
      user: 'user/current',
      event: 'event/current',
      stream: 'stream/current',
      appSettings: 'appsettings/current',
    }),
    speakers() {
      return this.speakerList;
    },
  },
  methods: {},
  created() {
    // get Widgets
    axios({
      method: 'GET',
      url: 'https://api-lb.livecasthq.com/speakers/',
      params: {
        webcastId: this.event.webcastId,
      },
    })
      .then((res) => {
        this.speakerList = res.data.data;
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
};
</script>

<style lang="less" scoped>
.speaker-list {
  margin: 0;
  padding: 0 0 8px 0;
  &-item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
    .speaker-img {
      display: block;
      width: 64px;
      height: 64px;
      margin-right: 12px;
      img {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
    .speaker-info-wrapper {
      .speaker-name {
        display: block;
        margin: 0 0 4px 0;
        padding: 0;
        font-size: 14px;
        line-height: 1;
        font-weight: 500;
        color: #272727;
      }
      .speaker-title {
        display: block;
        margin: 0;
        padding: 0;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: #727171;
      }
    }
  }
}
</style>
