<template>
  <div class="download-list" v-if="!isLoading">
    <div class="download-list-item" v-for="d in downloads" :key="d._id">
      <div class="download-icon">
        <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.3594 5.14653H13.3809C13.4738 5.14653 13.5469 5.07348 13.5469 4.98051C13.5469 4.685 13.4174 4.4061 13.1916 4.22016L10.6582 2.10844C10.4656 1.94906 10.2266 1.86273 9.97422 1.86273C9.83809 1.86273 9.72852 1.9723 9.72852 2.10844V4.51567C9.72852 4.8643 10.0107 5.14653 10.3594 5.14653Z"
            fill="#727171"
          />
          <path
            d="M8.86523 4.51562V1.85938H4.51562C3.93125 1.85938 3.45312 2.3375 3.45312 2.92188V14.0781C3.45312 14.6625 3.93125 15.1406 4.51562 15.1406H12.4844C13.0688 15.1406 13.5469 14.6625 13.5469 14.0781V6.00977H10.3594C9.53594 6.00977 8.86523 5.33906 8.86523 4.51562Z"
            fill="#727171"
          />
        </svg>
      </div>
      <div class="download-name">
        <a :href="d.filePath" target="_blank">{{ d.name }}</a>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import axios from 'axios';

export default {
  name: 'Downloads',
  data() {
    return {
      downloads: {},
      fileUrl: null,
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters({
      streamList: 'stream/list',
      widgetList: 'widget/list',
      user: 'user/current',
      event: 'event/current',
      stream: 'stream/current',
      appSettings: 'appsettings/current',
    }),
  },
  methods: {},
  created() {
    // get Widgets
    axios({
      method: 'GET',
      url: 'https://api-lb.livecasthq.com/download/',
      params: {
        webcastId: this.event.webcastId,
        '$sort[createdAt]': -1,
      },
    })
      .then((res) => {
        this.downloads = res.data.data;
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
};
</script>

<style lang="less">
.download-list {
  margin: 0;
  padding: 0;
  &-item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 8px;
    .download-icon {
      width: 18px;
      margin-right: 12px;
      svg {
        display: block;
        width: 18px;
        height: auto;
      }
    }
    .download-name {
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: #272727;
      a {
        color: #272727;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
