<template>
  <div class="edit-person-info">
    <!-- personInfo form -->
    <Alert type="warning" style="margin-bottom: 15px; font-size: 14px"
      >Please enter your name</Alert
    >
    <Form :model="infoForm">
      <FormItem :label="Name">
        <Input v-model="infoForm.name" required />
      </FormItem>

      <FormItem :label="Location">
        <Input v-model="infoForm.location" />
      </FormItem>

      <FormItem style="margin-bottom: 0px">
        <Button type="success" @click="updateSenderInfo">Save</Button>
      </FormItem>
    </Form>
  </div>
</template>

<script>
export default {
  props: ['senderInfo'],
  data() {
    return {
      infoForm: {
        name: null,
        location: null,
      },
    };
  },
  methods: {
    updateSenderInfo() {
      this.$emit('updateSenderInfoEvent', this.infoForm);
    },
  },
  created() {
    this.infoForm = {
      name: this.senderInfo.name,
      location: this.senderInfo.location,
    };
  },
};
</script>

<style scoped lang="less">
.ivu-input {
  font-size: 14px;
  line-height: 1.4 !important;
}
</style>
