<template>
  <div class="video js-video-wrapper" v-if="isReady">
    <div
      class="embed-responsive"
      :class="{
        'embed-responsive-16by9': is16by9,
        'embed-responsive-4by3': !is16by9,
      }"
      :style="{ width: ratio.w + 'px', height: ratio.h + 'px' }"
    >
      <div id="player" ref="player" class="player embed-responsive-item"></div>
    </div>
    <div
      class="slide-thumb"
      style="display: none"
      v-for="(v, slideId) in slideData.slideCount"
      :key="slideId"
    >
      <img :src="slidePath + slideId + '.png'" />
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import Clappr from 'clappr';
import trackPlayer from '@/utils/playerReports';
import ClapprStats from 'clappr-stats';
import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';

export default {
  data() {
    return {
      player: null,
      selectedStream: null,
      actionData: [],
      currentSN: null,
      expectedActions: [],
      currentOffset: null,
      expectedOffset: null,
      expectedSN: null,
      currentSlide: null,
      fragPlaying: null,
      isReady: false,
      is16by9: false,
      ratioInterval: null,
      slides: null,
      ratio: {
        w: null,
        h: null,
      },
    };
  },
  watch: {
    webcastStatus(s) {
      if (s != 'live') {
        if (this.player) {
          this.player.stop();
          this.player.destroy();
          this.player = null;
        }
      } else {
        this.createPlayer();
      }
    },
    webcastCurrentSessionId(sid) {},
    selectedStream() {
      if (this.player) {
        this.player.stop();
        this.player.load(this.playerUrl, true);
        this.player.play();
      }
    },
  },
  computed: {
    ...mapGetters({
      streamList: 'stream/list',
      user: 'user/current',
      event: 'event/current',
      stream: 'stream/current',
      appSettings: 'appsettings/current',
    }),
    webcast() {
      return this.event;
    },
    slidePath() {
      return `https://lcp.ams3.cdn.digitaloceanspaces.com/${this.slideData.path}/${this.slideData.path}-`;
    },
    webcastStatus() {
      return this.event.status;
    },
    webcastCurrentSessionId() {
      return this.webcast.currentSessionId;
    },
    hasStreams() {
      // if(this.webcast.status != 'live') return false;
      return this.streamList.length > 1;
    },

    playerUrl() {
      const vodCDN = 'https://d16iq23b3mm9bf.cloudfront.net/recordings/';
      // let vodCDN = "https://live.cdn22.livecasthq.com/irrecordings/";
      return vodCDN + this.webcast.recordingFile;
    },
    statsData() {
      return {
        event: this.event,
        stream: this.stream,
        user: this.user,
      };
    },
  },
  methods: {
    ...mapActions('session-connections', {
      track: 'track',
    }),
    ...mapActions('slides', {
      findSlide: 'find',
    }),
    preloadSlides() {
      let img;
      for (let i = 0, len = this.slides.count; i < len; ++i) {
        `${this.slidePath + i}.png`;
        img = new Image();
        img.src = imgs[i];
      }
    },
    ratioHandler() {
      const wrapper = {
        w: document.querySelector('.js-video-wrapper').offsetWidth,
        h: document.querySelector('.js-video-wrapper').offsetHeight,
      };
      let video = {
        w: null,
        h: null,
      };
      if (this.is16by9) {
        video =
          (wrapper.w / 16) * 9 <= wrapper.h
            ? { w: wrapper.w, h: (wrapper.w / 16) * 9 }
            : { w: (wrapper.h / 9) * 16, h: wrapper.h };
      } else {
        video =
          (wrapper.w / 4) * 3 <= wrapper.h
            ? { w: wrapper.w, h: (wrapper.w / 4) * 3 }
            : { w: (wrapper.h / 3) * 4, h: wrapper.h };
      }
      if (this.ratio.w !== video.w || this.ratio.h !== video.h) {
        this.ratio = video;
      }
    },
    getSlideId(data) {
      console.log(data);
      let id3Str = null;
      const currentPlayback = this.$data.player.core.getCurrentPlayback();

      if (currentPlayback._hls) {
        if (currentPlayback._hls.media.textTracks[0]) {
          const { activeCues } = currentPlayback._hls.media.textTracks[0];
          if (activeCues && activeCues.length >= 1) {
            const textTrack = activeCues[0];
            if (textTrack.value) {
              id3Str = textTrack.value.info.substring(14);
            }
          }
        }
      } else {
        /* condution for iOS */
        id3Str = data.target.activeCues[0].value.data;
      }

      const id3Json = JSON.parse(id3Str);
      console.log(id3Json);
      if (id3Json) {
        return id3Json.time;
      }
      return null;
    },
    getSlideIdVOD(timestamp) {},
    updateSlide(slideId) {
      if (slideId && this.currentSlide != slideId) {
        const posterUrl = `${this.slidePath + slideId}.png`;
        const currentPlayback = this.$data.player.core.getCurrentPlayback();

        if (currentPlayback._hls) {
          const poster = this.player.getPlugin('poster');
          poster.options.poster = posterUrl;
          poster.render();
          poster.update();
        } else {
          this.player.configure({ poster: posterUrl });
        }

        if (!!window.MSInputMethodContext && !!document.documentMode) {
          // IF browser is IE11
          document.querySelector('.webinar-media-content-wrapper video').poster = posterUrl;
        }

        this.currentSlide = slideId;
      }
    },

    createPlayer() {
      const self = this;

      setTimeout(() => {
        this.$data.player = new Clappr.Player({
          source: self.playerUrl,
          parentId: '#player',
          Preload: 'metadata',
          width: '100%',
          height: '100%',
          allowUserInteraction: true,
          autoPlay: true,
          maxBufferLength: 1,
          plugins: [ClapprStats],
          playback: {
            playInline: true,
          },
          clapprStats: {
            runEach: 20000,
            onReport: (metrics) => {
              metrics.isPlaying = self.player.isPlaying();
              trackPlayer.metrics(metrics, self.statsData);
            },
          },
          events: {
            onReady() {
              const posterUrl = `${self.slidePath}0.png`;
              this.configure({ poster: posterUrl });
              trackPlayer.ready(self.statsData);
            },
            onPlay() {
              trackPlayer.play(self.statsData);
            },
            onPause() {
              trackPlayer.pause(self.statsData);
            },
            onStop() {
              trackPlayer.stop(self.statsData);
            },
            onTimeUpdate(time) {},
          },
        });

        this.$data.player.core
          .getCurrentPlayback()
          .on(Clappr.Events.PLAYBACK_PLAY_INTENT, (data) => {
            const currentPlayback = this.$data.player.core.getCurrentPlayback();
            const activeCues = null;
            console.log(this.slideData);
            const slideTiming = this.slideData.timing;
            const start = moment(this.webcast.recordingStarted).valueOf();
            this.player.on(Clappr.Events.PLAYER_TIMEUPDATE, (data) => {
              const currentTime = start + data.current * 1000;
              console.log(currentTime);
              const slideTime = _.findLast(slideTiming, (value, key) => key < currentTime);
              this.updateSlide(slideTime);
              // console.log(data.current);
            });
          });
      }, 100);
    },
  },
  created() {
    this.$data.selectedStream = this.stream;

    const data = axios({
      method: 'GET',
      url: 'https://api-lb.livecasthq.com/slides/',
      params: {
        webcastId: this.event.webcastId,
        '$sort[createdAt]': -1,
        $limit: 1,
      },
    }).then((res) => {
      this.slideData = res.data.data[0];
      this.is16by9 = this.slideData.is16by9;
      this.isReady = true;
      this.createPlayer(Clappr);
    });
  },
  mounted() {
    this.ratioInterval = setInterval(() => {
      this.ratioHandler();
    }, 100);
  },
  destroyed() {
    if (this.player) {
      this.player.destroy();
      this.player = null;
    }
    clearInterval(this.ratioInterval);
    this.ratioInterval = null;
  },
};
</script>

<style>
.player-poster {
  display: -webkit-box !important;
  background-size: contain !important;
  background-color: transparent !important;
}
#player .container {
  max-width: 100% !important;
  max-height: calc(100vh - 64px);
  background-color: transparent !important;
}
</style>
<style lang="less" scoped>
.video {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.embed-responsive-16by9::before {
  @media (max-width: 575px) {
    padding-top: 56.25% !important;
  }
}
.embed-responsive-4by3::before {
  @media (max-width: 575px) {
    padding-top: 75% !important;
  }
}
.js-video-wrapper .embed-responsive {
  @media (min-width: 576px) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @media (max-width: 575px) {
    width: 100% !important;
    height: auto !important;
  }
}
</style>
