<template>
  <!--
  <section class="qna-actions" v-if="isUserRegistered" :class="{
      'new-question': newQuestionExpanded,
      'edit-sender': editingPersonInfo}">
    <question-form></question-form>
  </section>
  -->
  <section class="qna-actions">
    <question-form
      v-if="!editingSenderInfo"
      :senderInfo="senderInfo"
      @switchToSenderEditEvent="isEditingSender"
    ></question-form>
    <sender-form
      v-if="editingSenderInfo"
      :senderInfo="senderInfo"
      @switchToSenderEditEvent="isEditingSender"
      @updateSenderInfoEvent="updateSenderInfo"
    ></sender-form>
  </section>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import QuestionForm from '@/components/widget/QuestionForm.vue';
import SenderForm from '@/components/widget/QnaSenderForm.vue';

export default {
  name: 'QnaActions',
  data() {
    return {
      editingSenderInfo: false,
      senderInfo: {
        name: null,
        location: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      streamList: 'stream/list',
      widgetList: 'widget/list',
      user: 'user/current',
      event: 'event/current',
      stream: 'stream/current',
      appSettings: 'appsettings/current',
    }),
  },
  components: {
    QuestionForm,
    SenderForm,
  },
  methods: {
    isEditingSender(isEdit) {
      this.editingSenderInfo = isEdit;
    },
    updateSenderInfo(data) {
      this.senderInfo = data;
      this.isEditingSender(false);
    },
  },
  created() {
    // console.log(_.get(this, 'registration.name'));
    if (!_.get(this, 'user.name')) {
      this.isEditingSender(true);
    } else {
      this.senderInfo = {
        name: this.user.name,
        location: this.user.location,
      };
      if (_.has(this.user, 'info.organization')) {
        this.senderInfo.location = this.user.info.organization;
      }
    }
  },
};
</script>

<style lang="less">
.qna-actions {
  padding: 0;
  bottom: 0;
  position: absolute;
  width: 100%;
  background: #fff;
  z-index: 2920;
  @media screen and (max-width: 992px) {
    position: fixed;
    bottom: 0;
  }
}
.qna-actions-embed {
  position: relative;
  border: none;
  border-bottom: 0.5px solid #e9eaec;
}
</style>
