import Vue from 'vue';
import Vuex from 'vuex';
import event from '@/store/modules/event';
import stream from '@/store/modules/stream';
import user from '@/store/modules/user';
import appsettings from '@/store/modules/appsettings';
import widget from '@/store/modules/widget';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    event,
    stream,
    user,
    appsettings,
    widget,
  },
});
