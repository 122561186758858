<template>
  <section class="header">
    <!--
    <h3 class="title" v-if="widgetData.title">{{ widgetData.title }}</h3>
    -->
    <Menu
      theme="light"
      v-if="widgets && widgets.length"
      mode="horizontal"
      @on-select="selectWidget"
    >
      <MenuItem
        style="font-size: 14px"
        v-for="(widget, index) in widgets"
        :key="index"
        :name="index"
        >{{ widget.title }}</MenuItem
      >
    </Menu>
  </section>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      widgets: 'widget/list',
    }),
  },
  methods: {
    selectWidget(index) {
      this.$emit('switchToWidgetEvent', index);
    },
  },
};
</script>

<style>
</style>
