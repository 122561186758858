<template>
  <div class="video js-video-wrapper" v-if="isReady">
    <div
      class="embed-responsive embed-responsive-16by9"
      :style="{ width: ratio.w + 'px', height: ratio.h + 'px' }"
    >
      <div id="player" ref="player" class="player embed-responsive-item">
        <iframe
          :src="event.videoPlayerUrl + '?autoplay=1&autopause=0'"
          allowfullscreen
          frameborder="0"
        />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-underscore-dangle */
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      isReady: true,
      is16by9: true,
      ratioInterval: null,
      slides: null,
      ratio: {
        w: null,
        h: null,
      },
    };
  },
  methods: {
    ratioHandler() {
      const wrapper = {
        w: document.querySelector('.js-video-wrapper').offsetWidth,
        h: document.querySelector('.js-video-wrapper').offsetHeight,
      };
      let video = {
        w: null,
        h: null,
      };
      if (this.is16by9) {
        video = (wrapper.w / 16) * 9 <= wrapper.h
          ? { w: wrapper.w, h: (wrapper.w / 16) * 9 }
          : { w: (wrapper.h / 9) * 16, h: wrapper.h };
      } else {
        video = (wrapper.w / 4) * 3 <= wrapper.h
          ? { w: wrapper.w, h: (wrapper.w / 4) * 3 }
          : { w: (wrapper.h / 3) * 4, h: wrapper.h };
      }
      if (this.ratio.w !== video.w || this.ratio.h !== video.h) {
        this.ratio = video;
      }
    },
  },
  computed: {
    ...mapGetters({
      streamList: 'stream/list',
      user: 'user/current',
      event: 'event/current',
      stream: 'stream/current',
      appSettings: 'appsettings/current',
    }),
    webcast() {
      return this.event;
    },
    webcastStatus() {
      return this.event.status;
    },
    webcastCurrentSessionId() {
      return this.webcast.currentSessionId;
    },
  },
  mounted() {
    this.ratioInterval = setInterval(() => {
      this.ratioHandler();
    }, 100);
  },
};
</script>
<style>
.player-poster {
  display: -webkit-box !important;
  background-size: contain !important;
  background-color: transparent !important;
}
#player .container {
  max-width: 100% !important;
  max-height: calc(100vh - 64px);
  background-color: transparent !important;
}
</style>
<style lang="less" scoped>
.video {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.embed-responsive-16by9::before {
  @media (max-width: 575px) {
    padding-top: 56.25% !important;
  }
}
.embed-responsive-4by3::before {
  @media (max-width: 575px) {
    padding-top: 75% !important;
  }
}
.js-video-wrapper .embed-responsive {
  @media (min-width: 576px) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @media (max-width: 575px) {
    width: 100% !important;
    height: auto !important;
  }
}
</style>
